<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{ account: mastodon.v1.Account }>()
const name = computed(() => getDisplayName(props.account, { rich: true }))
</script>

<template>
  <div class="account-badge">
    <AccountHover :account="account">
      <AccountAvatar :account="account" />
    </AccountHover>
    <div class="account-info">
      <div class="account-name">
        {{ name }}
      </div>
      <div class="desc">
        <slot name="meta" />
      </div>
    </div>
  </div>
</template>

<style>
.account-badge {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: var(--padding-small);

  .popup-trigger {
    padding: 0;
    border-radius: calc(var(--button-height) * var(--border-radius-fact));
  }
  .account-info {
    text-align: start;
  }
}
</style>
